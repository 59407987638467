<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <location-form :item="location">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </location-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "@/components/elements/callback-button.vue";
import LocationForm from "../../../../components/forms/location-form.vue";

export default {
    components: {LocationForm, CallbackButton},

    computed: {
        ...mapGetters({
            location: 'location/item'
        }),

        title: function () {
            return this.location?.id ? this.$tc('organizations::locations.location', 2).ucFirst() : this.$t('base.create_new_item', {item: this.$tc('organizations::locations.location', 1)}).ucFirst()
        },

        link: function () {
            const path = '/master-data/organizations/locations';
            return {path: this.$t('routes.' + path)}
        },
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        }
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('location/show', {id: this.$route.params.id})
        }
    },

    unmounted() {
        this.$store.dispatch('location/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
