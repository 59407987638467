<script>
import {mapGetters} from "vuex";
import IconInput from "../elements/icon-input.vue";

export default {
    name: "location-form",

    components: {IconInput},

    emits: ['saved', 'update', 'add'],

    props: {
        item: {
            type: Object,
            required: true
        },

        withoutSaveBtn: {
            type: Boolean,
            default: false
        },

        doNotSave: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            loading: 'location/loading',
            states: 'location/states',
            errors: 'location/errors',
            addressTypes: 'addressType/all',
            countries: 'country/all',
            streetTypes: 'streetType/all',
            zipCodes: 'zipCode/all',
            locale: 'trans/locale',
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)
            const reloadZipCodes = !data.zip_code_id

            if (!this.doNotSave) {
                return this.$store.dispatch(`location/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (reloadZipCodes) {
                        this.$store.dispatch('zipCode/all', true);
                    }
                    if (method === 'create') {
                        this.$router.push(this.$t('routes./master-data/organizations/locations/:id(\\d+)', {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                if (this.model.id) {
                    this.$emit('update', this.model);
                } else {
                    this.$emit('add', this.model);
                }
            }
        },

        setModel: function (model = {}) {
            const base = {}

            this.model = Object.assign(
                {},
                base,
                JSON.parse(JSON.stringify(model))
            )
        },

        fetchAddressTypes: function () {
            this.$store.dispatch('addressType/all')
        },

        fetchCountries: function () {
            this.$store.dispatch('country/all')
        },

        fetchZipCodes: function () {
            this.$store.dispatch('zipCode/all')
        },

        fetchStreetTypes: function () {
            this.$store.dispatch('streetType/all', {locale: this.locale})
        },

        setCity: function () {
            const zipCode = this.zipCodes.find(zip => zip.zip_code === this.model.zip_code)

            if (zipCode) {
                this.model.city = zipCode.city
                this.model.zip_code_id = zipCode.id
            }

            this.setAddress()
        },

        setAddress: function () {
            const country = this.countries.find(country => country.id === this.model.country_id)
            const zipCode =  this.zipCodes.find(zip => zip.id === this.model.zip_code_id)
            const streetType = this.streetTypes.find(type => type.id === this.model.street_type_id)

            const zip = zipCode
                ? zipCode.zip_code + ' ' + zipCode.city
                : this.model.zip_code + ' ' + this.model.city


            if (country && (zipCode || (this.model.zip_code && this.model.city)) && streetType) {
                this.model.address = zip + ', ' + (this.model.street_name || '') + ' ' + streetType.name + ' ' + (this.model.house_number || '') + ', ' + (country.translation_key ? this.$t(country.translation_key) : country.name);
            }
        },
    },

    watch: {
        item: function (value) {
            this.setModel(value)
        },

        model: {
            deep: true,
            immediate: true,
            handler: function (value) {
                this.$emit('update', Object.assign({}, value))
            },
        }
    },

    created() {
        this.setModel(this.item)
        this.fetchZipCodes()
        this.fetchCountries()
        this.fetchStreetTypes()
        this.fetchAddressTypes()
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('organizations::locations.columns.name').ucFirst() }}</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('organizations::locations.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.color === false}"
                        class="form-label"
                        for="color"
                    >{{ $t('organizations::locations.columns.color').ucFirst() }}</label
                    >
                    <input
                        id="color"
                        v-model="model.color"
                        :class="{'border-danger': states.color === false}"
                        :placeholder="$t('organizations::locations.placeholders.color').ucFirst()"
                        class="form-control form-control-color"
                        name="color"
                        type="color"
                    />
                    <div :class="{'d-block': states.color === false}" class="invalid-feedback">{{ errors.color }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <icon-input
                        id="icon"
                        v-model="model.icon"
                        :class="{'border-danger': states.icon === false}"
                        :placeholder="$t('organizations::locations.placeholders.icon').ucFirst()"
                        class="form-control"
                        name="icon"
                        :state="states.icon"
                        :error="errors.icon"
                    >{{ $t('organizations::locations.columns.icon').ucFirst() }}</icon-input>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.is_active === false}"
                        class="form-label"
                        for="is_active"
                    >{{ $t('organizations::locations.columns.is_active').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="is_active"
                               v-model="model.is_active"
                               class="form-check-input float-none mx-3"
                               type="checkbox"
                        >
                    </div>
                    <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                        {{ errors.is_active }}
                    </div>
                </div>
                <div class="col-3 mb-4">
                    <label
                        :class="{'text-danger': states.address_type_id === false}"
                        class="form-label"
                        for="address_type_id-input"
                    >{{ $t('organizations::locations.columns.address_type_id').ucFirst() }}</label
                    >
                    <select
                        id="address_type_id-input"
                        v-model="model.address_type_id"
                        :class="{'border-danger': states.address_type_id === false}"
                        class="form-select"
                        name="address_type_id"
                    >
                        <option :value="model.address_type_id === undefined ? undefined : null">
                            {{ $t('organizations::locations.placeholders.address_type_id').ucFirst() }}
                        </option>
                        <option v-for="(addressType, index) in addressTypes" :key="index" :value="addressType.id">{{
                                addressType.translation_key ? $t(addressType.translation_key).ucFirst() : addressType.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.address_type_id === false}" class="invalid-feedback">{{
                            errors.address_type_id
                        }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.country_id === false}"
                        class="form-label"
                        for="country_id-input"
                    >{{ $t('organizations::locations.columns.country_id').ucFirst() }}</label
                    >
                    <select
                        id="country_id-input"
                        v-model="model.country_id"
                        :class="{'border-danger': states.country_id === false}"
                        class="form-select"
                        name="country_id"
                        @change="setAddress"
                    >
                        <option :value="model.country_id === undefined ? undefined : null">
                            {{ $t('organizations::locations.placeholders.country_id').ucFirst() }}
                        </option>
                        <option v-for="(country, index) in countries" :key="index" :value="country.id">
                            {{ country.translation_key ? $t(country.translation_key).ucFirst() : country.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.country_id === false}" class="invalid-feedback">{{
                            errors.country_id
                        }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.zip_code === false}"
                        class="form-label"
                        for="zip_code"
                    >{{ $t('addresses::zip_codes.columns.zip_code').ucFirst() }}</label
                    >

                    <input
                        id="zip_code"
                        list="zip-codes"
                        v-model="model.zip_code"
                        :class="{'border-danger': states.zip_code === false}"
                        :placeholder="$t('addresses::zip_codes.placeholders.zip_code').ucFirst()"
                        class="form-control"
                        name="zip_code"
                        type="text"
                        @change="setCity"
                    />
                    <datalist id="zip-codes">
                        <option v-for="(zipCode, index) in zipCodes" :key="index" :value="zipCode.zip_code">
                            {{ zipCode.zip_code }} {{ zipCode.city }}
                        </option>
                    </datalist>
                    <div :class="{'d-block': states.zip_code === false}" class="invalid-feedback">{{
                            errors.zip_code
                        }}
                    </div>
                </div>

                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.city === false}"
                        class="form-label"
                        for="city"
                    >{{ $t('addresses::zip_codes.columns.city').ucFirst() }}</label
                    >
                    <input
                        id="city"
                        v-model="model.city"
                        :class="{'border-danger': states.city === false}"
                        :placeholder="$t('addresses::zip_codes.placeholders.city').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.city === false}" class="invalid-feedback">{{ errors.city }}</div>
                </div>
                <div v-if="false" class="col-lg-3">
                    <label
                        :class="{'text-danger': states.zip_code_id === false}"
                        class="form-label"
                        for="zip_code_id-input"
                    >{{ $t('organizations::locations.columns.zip_code_id').ucFirst() }}</label
                    >
                    <select
                        id="zip_code_id-input"
                        v-model="model.zip_code_id"
                        :class="{'border-danger': states.zip_code_id === false}"
                        class="form-select"
                        name="zip_code_id"
                        @change="setAddress"
                    >
                        <option :value="model.zip_code_id === undefined ? undefined : null">
                            {{ $t('organizations::locations.placeholders.zip_code_id').ucFirst() }}
                        </option>
                        <option v-for="(zipCode, index) in zipCodes" :key="index" :value="zipCode.id">
                            {{ zipCode.zip_code }} {{ zipCode.city }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.zip_code_id === false}" class="invalid-feedback">{{
                            errors.zip_code_id
                        }}
                    </div>
                </div>

                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.street_name === false}"
                        class="form-label"
                        for="street_name-input"
                    >{{ $t('organizations::locations.columns.street_name').ucFirst() }}</label
                    >
                    <input
                        id="street_name-input"
                        v-model="model.street_name"
                        :class="{'border-danger': states.street_name === false}"
                        :placeholder="$t('organizations::locations.placeholders.street_name')"
                        class="form-control"
                        name="street_name"
                        type="text"
                        @change="setAddress"
                    />
                    <div :class="{'d-block': states.street_name === false}" class="invalid-feedback">
                        {{ errors.street_name }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.street_type_id === false}"
                        class="form-label"
                        for="street_type_id-input"
                    >{{ $t('organizations::locations.columns.street_type_id').ucFirst() }}</label
                    >
                    <select
                        id="street_type_id-input"
                        v-model="model.street_type_id"
                        :class="{'border-danger': states.street_type_id === false}"
                        class="form-select"
                        name="street_type_id"
                        @change="setAddress"
                    >
                        <option :value="model.street_type_id === undefined ? undefined : null">
                            {{ $t('organizations::locations.placeholders.street_type_id').ucFirst() }}
                        </option>
                        <option v-for="(streetType, index) in streetTypes" :key="index" :value="streetType.id">
                            {{ streetType.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.street_type_id === false}" class="invalid-feedback">{{
                            errors.street_type_id
                        }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.house_number === false}"
                        class="form-label"
                        for="house_number-input"
                    >{{ $t('organizations::locations.columns.house_number').ucFirst() }}</label
                    >
                    <input
                        id="house_number-input"
                        v-model="model.house_number"
                        :class="{'border-danger': states.house_number === false}"
                        :placeholder="$t('organizations::locations.placeholders.house_number')"
                        class="form-control"
                        name="house_number"
                        type="text"
                        @change="setAddress"
                    />
                    <div :class="{'d-block': states.house_number === false}" class="invalid-feedback">
                        {{ errors.house_number }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-4">
                    <label
                        :class="{'text-danger': states.address === false}"
                        class="form-label"
                        for="address-input"
                    >{{ $t('organizations::locations.columns.address').ucFirst() }}</label
                    >
                    <input
                        id="address-input"
                        v-model="model.address"
                        :class="{'border-danger': states.address === false}"
                        :placeholder="$t('organizations::locations.placeholders.address')"
                        class="form-control"
                        name="address"
                        readonly
                        type="text"
                    />
                    <div :class="{'d-block': states.address === false}" class="invalid-feedback">
                        {{ errors.address }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button v-if="!withoutSaveBtn" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
